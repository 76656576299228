<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.2 375.19"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M262.61,375.18q-101,0-202,0c-12.39,0-24.15-2-34.6-9.33C9.38,354.29.12,338.67.1,318.29q-.18-130.5,0-261c0-27.88,19-50.51,46.31-56.09a70.37,70.37,0,0,1,13.93-1C195.2.12,330.06.3,464.91,0c28.54-.06,48.51,16,56.52,37.28a61.38,61.38,0,0,1,3.59,21q.35,129.26.13,258.52c0,29-17.83,51.15-46.09,57.21a71.33,71.33,0,0,1-14.91,1.11Q363.38,375.24,262.61,375.18ZM487.13,93.77c0-14.46.47-28.43-.21-42.33-.31-6.14-5.05-10.55-10.94-12.5a35.51,35.51,0,0,0-10.82-1.26q-202.54-.07-405.09,0c-1.83,0-3.67,0-5.5.11-6.3.34-11.64,3.11-14.21,8.71a34.46,34.46,0,0,0-2.7,13.93q-.22,127.26-.09,254.53c0,2.49.17,5,.4,7.48a15.13,15.13,0,0,0,9,12.91c3.92,1.76,8.69,2.27,13.08,2.27q202.55.18,405.08.09c1.84,0,3.82.38,5.47-.19,3.84-1.33,7.91-2.55,11.16-4.86,5.62-4,5.88-10.44,5.88-16.71q0-89.51,0-179c0-1.62-.16-3.24-.28-5.55H56.49V93.77Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconCard',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
